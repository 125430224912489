import React from "react"

import { Link } from "gatsby"
import CloseMenu from "./CloseMenu"

export default function Nav({ inverted }) {
  return (
    <nav className={`c-primary-nav ${inverted && `c-primary-nav--inverted`}`}>
      <CloseMenu />

      <ul className="c-primary-nav__list">
        <li className="c-primary-nav__item">
          <Link activeClassName="is-active" to="/over-ons">
            Over ons
          </Link>
        </li>
        <li className="c-primary-nav__item">
          <Link activeClassName="is-active" to="/diensten">
            Diensten
          </Link>
        </li>
        <li className="c-primary-nav__item">
          <Link activeClassName="is-active" to="/realisaties">
            Realisaties
          </Link>
        </li>
        <li className="c-primary-nav__item">
          <Link activeClassName="is-active" to="/contact">
            Contact
          </Link>
        </li>
      </ul>
    </nav>
  )
}

/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"

import PropTypes from "prop-types"

import "../scss/main.scss"

import CTA from "./CTA"
import Footer from "./Footer"
import Header from "./Header"

const Layout = ({ children, noHeader }) => {
  return (
    <>
      {!noHeader && <Header />}
      {children}
      <CTA />
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

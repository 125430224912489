import React from "react"

export default function CloseMenu() {
  return (
    <button
      className="c-menu-button c-menu-button--close js-close-menu"
      onClick={() => {
        document.querySelector("body").classList.remove("open-menu")
      }}
    >
      <span className="u-hidden-visually">Sluit menu</span>
      <svg width="28" height="27" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M4.454.419l22.627 22.627-3.535 3.535L.919 3.954z"
          fill="#FFF"
          fillRule="evenodd"
        />
        <path
          d="M.919 23.046L23.546.419l3.535 3.535L4.454 26.581z"
          fill="#FFF"
          fillRule="evenodd"
        />
      </svg>
    </button>
  )
}

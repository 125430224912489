import React from "react"

import logo from "../img/aximet-logo.svg"
import Nav from "./Nav"
import { Link } from "gatsby"
import MenuButton from "./MenuButton"

export default function Header() {
  return (
    <header className="c-header">
      <div className="c-header__inner">
        <Link to="/" className="c-page-logo">
          <img src={logo} width="208" height="32" alt="Aximet" />
        </Link>

        <MenuButton />

        <Nav />
      </div>
    </header>
  )
}

import React from "react"
import { Link } from "gatsby"

export default function CTA() {
  return (
    <div className="c-cta">
      <div className="u-inner">
        <h3 className="c-cta__title">Hoe kunnen we u van dienst zijn?</h3>
        <Link to={"/contact"} className="c-button">
          Contacteer ons
        </Link>
      </div>
    </div>
  )
}

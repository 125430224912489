import React from "react"

import emblem from "../img/aximet-emblem.svg"

export default function Footer() {
  return (
    <footer className="c-footer">
      <div className="u-inner">
        <div className="o-grid">
          <div className="o-grid__cell u-1/1 u-2/4@desktop">
            <a href="/" className="c-footer-logo">
              <img
                src={emblem}
                width="50"
                height="52"
                alt="Aximet | Las- en constructriewerken"
              />
              <div className="c-footer-logo__title">
                Aximet
                <br />
                Flexibel met staal
              </div>
            </a>
          </div>
          <div className="o-grid__cell u-1/1 u-1/4@desktop">
            Nobels-Peelmanstraat 6<br />
            9100 Sint-Niklaas
          </div>
          <div className="o-grid__cell u-1/1 u-1/4@desktop">
            +32 497 574756
            <br />
            <a href="mailto:info@aximet.be">info@aximet.be</a>
          </div>
        </div>
      </div>
    </footer>
  )
}

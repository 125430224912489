import React from "react"

export default function MenuButton() {
  return (
    <button
      onClick={() => {
        document.querySelector("body").classList.add("open-menu")
      }}
      className="c-menu-button js-show-menu"
    >
      <span className="u-hidden-visually">Menu</span>
      <svg width="32" height="25" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0 0h32v5H0zM0 10h32v5H0zM0 20h32v5H0z"
          fill="currentColor"
          fillRule="evenodd"
        />
      </svg>
    </button>
  )
}

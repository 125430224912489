/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, meta, keywords, title, ogImage }) {
  const { site, defaultOgImage } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }

        defaultOgImage: file(name: { eq: "asbest-hero" }) {
          ...SEOImage
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{ lang: "be-NL" }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: "google-site-verification",
          content: "FlVjr11aa51MeXjDCxNPvOwHQC0o1zw70h_Inv7xbdo",
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: `${site.siteMetadata.siteUrl}${
            ogImage
              ? ogImage.childImageSharp.resize.src
              : defaultOgImage.childImageSharp.resize.src
          }`,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
    >
      {/*
    @license
    MyFonts Webfont Build ID 193062

    The fonts listed in this notice are subject to the End User License
    Agreement(s) entered into by the website owner. All other parties are
    explicitly restricted from using the Licensed Webfonts(s).

    You may obtain a valid license from one of MyFonts official sites.
    http://www.fonts.com
    http://www.myfonts.com
    http://www.linotype.com
  */}
    </Helmet>
  )
}

SEO.defaultProps = {
  meta: [],
  keywords: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  ogImage: PropTypes.object,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

export const SEOQuery = graphql`
  fragment SEOImage on File {
    childImageSharp {
      resize(width: 1200, height: 630) {
        src
      }
    }
  }

  fragment SEO on MarkdownRemark {
    excerpt
    frontmatter {
      ogImage {
        ...SEOImage
      }
    }
  }
`

export default SEO
